import React from 'react';
import PropTypes from "prop-types";
import Tab from './Tab'

import hands_black from '../images/tabs/pies-y-manos.svg'
import hands_blue from '../images/tabs/pies-y-manos-blue.svg'
import hands_white from '../images/tabs/pies-y-manos-white.svg'

import depilacion_black from '../images/tabs/depilacion.svg'
import depilacion_blue from '../images/tabs/depilacion-blue.svg'
import depilacion_white from '../images/tabs/depilacion-white.svg'

import facial_black from '../images/tabs/facial.svg'
import facial_blue from '../images/tabs/facial-blue.svg'
import facial_white from '../images/tabs/facial-white.svg'


import estetica_black from '../images/tabs/estetica_black.svg'
import estetica_blue from '../images/tabs/estetica.svg'
import estetica_white from '../images/tabs/estetica_white.svg'

import corporal_black from '../images/tabs/corporal.svg'
import corporal_blue from '../images/tabs/corporal-blue.svg'
import corporal_white from '../images/tabs/corporal-white.svg'

import novia_black from '../images/tabs/novia.svg'
import novia_blue from '../images/tabs/novia-blue.svg'
import novia_white from '../images/tabs/novia-white.svg'

import novio_black from '../images/tabs/novio.svg'
import novio_blue from '../images/tabs/novio-blue.svg'
import novio_white from '../images/tabs/novio-white.svg'

import invitados_black from '../images/tabs/invitados.svg'
import invitados_blue from '../images/tabs/invitados-blue.svg'
import invitados_white from '../images/tabs/invitados-white.svg'



export default class Tabs extends React.Component {

    constructor() {
        super();
        this.state = {
            activeTab: 0,
            previousTab: -1,
            div: true
        };
        this.mapTabs = this.mapTabs.bind(this);
        this.getImage1 = this.getImage1.bind(this);
        this.getImage2 = this.getImage2.bind(this);
    }

    getImage1(image, isActiveTab) {
        if (image === 'pies-y-manos.svg') return isActiveTab ? hands_white : hands_black;
        if (image === 'depilacion.svg') return isActiveTab ? depilacion_white : depilacion_black;
        if (image === 'facial.svg') return isActiveTab ? facial_white : facial_black;
        if (image === 'estetica.svg') return isActiveTab ? estetica_white : estetica_black;
        if (image === 'corporal.svg') return isActiveTab ? corporal_white : corporal_black;
        if (image === 'novia.svg') return isActiveTab ? novia_white : novia_black;
        if (image === 'novio.svg') return isActiveTab ? novio_white : novio_black;
        if (image === 'invitados.svg') return isActiveTab ? invitados_white : invitados_black;
    }

    getImage2(image) {
        if (image === 'pies-y-manos.svg') return hands_blue;
        if (image === 'depilacion.svg') return depilacion_blue;
        if (image === 'facial.svg') return facial_blue;
        if (image === 'corporal.svg') return corporal_blue;
        if (image === 'novia.svg') return novia_blue;
        if (image === 'novio.svg') return novio_blue;
        if (image === 'invitados.svg') return invitados_blue;
        if (image === 'estetica.svg') return estetica_blue;

    }

    mapTabs() {
        const updateTab = (activeTab) => {
            const previousTab = this.state.activeTab;
            if (activeTab !== previousTab) {
                const div = !this.state.div;
                this.setState({ activeTab, previousTab, div });
            }
        };
        return this.props.labels.map((element, index) => {
            const isActive = index === this.state.activeTab;
            return <Tab
                key={index}
                number={index}
                image1={this.getImage1(this.props.images[index], isActive)}
                image2={this.getImage2(this.props.images[index])}
                label={element}
                isActive={isActive}
                callback={updateTab}
            />;
        });
    }

    render() {
        const createDOMPurify = require('dompurify');
        const { JSDOM } = require('jsdom');
        const window = (new JSDOM('')).window;
        const DOMPurify = createDOMPurify(window);

        const content = DOMPurify.sanitize(this.props.contents[this.state.activeTab]).split('</div>,<div').join('</div><div');
        const clases = 'tab-content slow animated fadeIn faster';
        return (
            <div className='tabs'>
                <ol className='tab-header'> {this.mapTabs()} </ol>
                {this.state.div ?
                    <div className={clases} dangerouslySetInnerHTML={{ __html: content }} />
                    :
                    <section className={clases} dangerouslySetInnerHTML={{ __html: content }} />
                }
            </div>
        );
    }
}


Tabs.propTypes = {
    labels: PropTypes.array.isRequired,
    images: PropTypes.array,
    contents: PropTypes.array.isRequired
};
