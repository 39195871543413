import React from 'react';
import PropTypes from "prop-types";

export default class Wave extends React.Component {

    render() {
        const styles = {
            position: 'absolute',
            width: '100%',
            height: (this.props.isMobile ? '210px' : '200px'),
            left: '0',
            bottom: '0'
        };
        const { fillColor } = { ...this.props };
        return (
            <svg style={styles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 68" enableBackground="new 0 0 1440 68">
                <path d="m1622.3 1937.7c0 0-410.7 169.1-913.4 75.5-502.7-93.6-977.7 56.3-977.7 56.3v440h1891.1v-571.8" fill={fillColor} transform="translate(0-1977)"></path>
            </svg>
        );
    }
}


Wave.propTypes = {
    fillColor: PropTypes.string.isRequired,
    isMobile: PropTypes.bool
};