import React from 'react';
import { Link } from "react-scroll";
import * as Constants from '../constants';
import PropTypes from "prop-types";
import MainLogo from '../images/main-logo.png';

export default class NavBar extends React.Component {

    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        this.getStyles = this.getStyles.bind(this);
        this.handleSetActive = this.handleSetActive.bind(this);
    }

    getStyles() {
        return {
            navigation: {
                position: 'fixed',
                top: 0,
                zIndex: 100,
                width: '100%',
                padding: '8px',
                maxHeight: '80px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                flexDirection: 'row',
                flexWrap: 'wrap',
                color: '#0063AB',
                boxShadow: '1px 2px 10px grey'
            },
            logo: {
                marginRight: 'auto',
                marginLeft: '4%'
            }
        };
    }

    handleSetActive(title) {
        if (title !== undefined){
            setTimeout(() => this.props.callback(title), 500);
        }
        if (this.props.isMobile){
            setTimeout(this.toggle, 100);
        }
    }

    toggle(){
        var element = document.getElementById('mobile-menu');
        var wrapper = document.getElementById('menu-wrapper');
        var arr = element.className.split(' ');
        if (arr.indexOf('menu-closed') === -1) {
            element.className = 'menu-closed';
            wrapper.className = 'mobile animated fadeOutRight fast';
        } else {
            element.className = 'menu-opened';
            wrapper.className = 'mobile menu-opened animated fadeInRight fast';
        }
    }

    getMenuIcon() {
        return (
            <div id='mobile-menu' className='menu-closed' onClick={this.toggle}>
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
        );
    }

    render() {
        const styles = this.getStyles();
        const offset = -90;
        const isMobile = this.props.isMobile;
        return (
            <nav style={styles.navigation}>
                <Link smooth={true} to={Constants.HEADER} className={'home-link' + (isMobile ? ' mobile' : '')}>
                    <img style={styles.logo} src={MainLogo} alt='MainLogo'></img>
                </Link>
                {isMobile && this.getMenuIcon()}
                <div id='menu-wrapper' className={(isMobile ? 'mobile menu-closed hidden' : '')}>
                    <div className={'menu-container' + (isMobile ? ' mobile' : '')}>
                        <Link offset={(isMobile ? offset + 0 : offset)} className='menu-item'
                            smooth={true}
                            to={(isMobile ? 'servicios' : Constants.TREATMENTS)}
                            onClick={() => this.handleSetActive(Constants.TREATMENTS)}
                        >
                            {Constants.TREATMENTS.toUpperCase()}
                        </Link>
                        <Link offset={(isMobile ? offset + 150 : offset)} className='menu-item'
                            smooth={true}
                            to={(isMobile ? 'servicios' : Constants.PROMOTIONS)}
                            onClick={() => this.handleSetActive(Constants.PROMOTIONS)}
                        >
                            {Constants.PROMOTIONS.toUpperCase()}
                        </Link>
                        <Link offset={(isMobile ? offset + 270 : offset)} className='menu-item'
                            smooth={true}
                            to={(isMobile ? 'servicios' : Constants.PARTIES)}
                            onClick={() => this.handleSetActive(Constants.PARTIES)}
                        >
                            {Constants.PARTIES.toUpperCase()}
                        </Link>
                        <Link offset={(isMobile ? offset + 370 : offset)} className='menu-item'
                            smooth={true}
                            to={(isMobile ? 'servicios' : Constants.WEDDINGS)}
                            onClick={() => this.handleSetActive(Constants.WEDDINGS)}
                        >
                            {Constants.WEDDINGS.toUpperCase()}
                        </Link>
                        <Link offset={offset} className='menu-item'
                            smooth={true}
                            to={'contacto'}
                            onClick={() => this.handleSetActive()}
                        >
                            CONTACTO
                    </Link>
                    </div>
                </div>
            </nav>
        );
    }
}


NavBar.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired
};