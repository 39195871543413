import React from 'react';
import PropTypes from "prop-types";


export default class Tab extends React.Component {

    render() {
        return (
            <li className={this.props.isActive ? 'activeTab' : ''} onClick={() => this.props.callback(this.props.number)}>
                <img className='image1' src={this.props.image1} alt='imagen'/>
                <img className='image2' src={this.props.image2} alt='imagen'/>
                <p>{this.props.label.toUpperCase()}</p>
            </li>
        );
    }
}


Tab.propTypes = {
    image1: PropTypes.string,
    image2: PropTypes.string,
    isActive: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    callback: PropTypes.func.isRequired
};
