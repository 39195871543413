import React from 'react';
import PropTypes from "prop-types";
import AnimateHeight from 'react-animate-height';
import * as Constants from '../constants';
import translation from '../i18n/es'
import DropdownButton from './DropdownButton';

export default class MobileService extends React.Component {

    constructor() {
        super();
        this.state = {
            activeButton: Constants.NONE,
            previousActiveButton: Constants.NONE,
        }
        this.getStyles = this.getStyles.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.renderTreatments = this.renderTreatments.bind(this);
        this.renderPromotions = this.renderPromotions.bind(this);
        this.renderParties = this.renderParties.bind(this);
        this.renderWeddings = this.renderWeddings.bind(this);
    }

    getStyles() {
        return {
            section: {
                margin: '18px',
                userSelect: 'none'
            },
            image: {
                width: '62px'
            },
            name: {
                color: 'rgb(162, 99, 18)',
                fontWeight: 'bold',
                margin: '0',
                letterSpacing: '0.2rem'
            }
        };
    }


    renderTreatments() {
        const callBack = (label) => {
            const activeButton = this.state.activeButton === label ? Constants.NONE : label;
            this.setState({ activeButton });
        };
        return (
            <div>
                {translation.treatments.map((element, index) =>
                    <DropdownButton
                        key={index}
                        label={element.title}
                        image={element.image}
                        content={element.content}
                        isActive={(element.title === this.state.activeButton)}
                        callBack={callBack} />
                )}
            </div>
        );
    }
    renderPromotions() {
        const handleError = (element) => {
            element.target.style = 'display: none';
        };
        return (
            <div className='promociones'>
                <img className='promocion' src={process.env.PUBLIC_URL + '/promocion1.jpg'} alt='Promoci&oacute;n1' />
                <img className='promocion' src={process.env.PUBLIC_URL + '/promocion2.jpg'} alt='Promoci&oacute;n2' onError={handleError} />
            </div>
        );
    }
    renderParties() {
        return (
            <div className='beauty-parties'>
                <div className='header'>
                    <p>Un d&iacute;a en Oceans exclusivamente para ti y tus amigas: desconexi&oacute;n, diversi&oacute;n y los mejores cuidados est&eacute;ticos.</p>
                    <p>¡Tú elijes el d&iacute;a y el pack* y nosotros nos encargamos del resto!</p>
                    <span>*Todos los packs incluyen aperitivo (bocados dulces y salados, zumo de naranja natural, caf&eacute;, infusiones, agua)</span>
                </div>
                <div className='packs'>

                    <div className='pack'>
                        <span>PACK 1</span>
                        <ul>
                            <li>Manicura Spa</li>
                            <li>Pedicura Spa</li>
                            <li>Depilación de cejas</li>
                        </ul>
                    </div>


                    <div className='pack'>
                        <span>PACK 2</span>
                        <ul>
                            <li>Manicura Spa</li>
                            <li>Depilación de cejas</li>
                            <li>Presoterapia 30 min</li>
                            <li>Tratamiento Iluminador Vitamina C</li>
                        </ul>
                    </div>


                    <div className='pack'>
                        <span>PACK 3</span>
                        <ul>
                            <li>Manicura Semipermanente</li>
                            <li>Presoterapia 30 min</li>
                            <li>Masaje Relajante localizado 30 min.</li>
                        </ul>
                    </div>


                    <div className='pack'>
                        <span>PACK 4</span>
                        <ul>
                            <li>Tratamiento facial Detox</li>
                            <li>Depilación de cejas</li>
                            <li>Presoterapia 30 min</li>
                        </ul>
                    </div>


                    <div className='pack'>
                        <span>PACK 5</span>
                        <ul>
                            <li>Manicura Tradicional</li>
                            <li>Pedicura Tradicional</li>
                            <li>Depilación de cejas</li>
                            <li>Tratamiento facial Detox</li>
                        </ul>
                    </div>

                </div>
            </div>
        );
    }


    renderWeddings() {
        const callBack = (label) => {
            const activeButton = this.state.activeButton === label ? Constants.NONE : label;
            this.setState({ activeButton });
        };
        return (
            <div>
                {translation.weddings.map((element, index) =>
                    <DropdownButton
                        key={index}
                        label={element.title}
                        image={element.image}
                        content={element.content}
                        isActive={(element.title === this.state.activeButton)}
                        callBack={callBack} />
                )}
            </div>
        );
    }


    renderContent(name) {
        if (name === Constants.TREATMENTS) return this.renderTreatments();
        if (name === Constants.PROMOTIONS) return this.renderPromotions();
        if (name === Constants.PARTIES) return this.renderParties();
        if (name === Constants.WEDDINGS) return this.renderWeddings();
    }


    render() {
        const styles = this.getStyles();
        const { image, name } = { ...this.props };
        return (
            <div>
                <div style={styles.section} name={name} onClick={this.props.onClick}>
                    <img style={styles.image} src={image} alt={name} />
                    <p style={styles.name}>{name}</p>
                </div>
                <AnimateHeight duration={500} height={this.props.isActive ? 'auto' : 0}>
                    <div className='mobile-details'>
                        <div className='mobile-border-up'>
                            <div className='left'></div>
                            <div className='right'></div>
                        </div>
                        <div className='mobile-content'>
                            <div className='mobile-content-border'></div>
                            <div className='mobile-content-text'>
                                {this.renderContent(name)}
                            </div>
                        </div>
                    </div>
                </AnimateHeight>
            </div>
        );
    }
}


MobileService.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};
