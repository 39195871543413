import React from 'react';
import AnimateHeight from 'react-animate-height';
import Wave from './Wave';
import Service from './Service';
import MobileService from './MobileService';
import Details from './Details';
import * as Constants from '../constants';
import PropTypes from "prop-types";


import tratamientos from '../images/tratamientos.svg'
import promociones from '../images/promociones.svg'
import b_party from '../images/beauty-parties.svg'
import bodas from '../images/estetica.svg'

export default class Services extends React.Component {

    constructor() {
        super();
        this.renderMobile = this.renderMobile.bind(this);
        this.renderDesktop = this.renderDesktop.bind(this);
    }

    handleClick(service) {
        this.props.callback(this.props.service === service ? Constants.NONE : service);
    }

    renderDesktop() {
        const service = this.props.service;
        const lastService = this.props.lastService;
        const servicesStyle = {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            marginTop: '50px'
        };
        return (
            <div className='wrapper'>
                <h1 className='title'>Nuestros Servicios</h1>
                <div style={servicesStyle}>
                    <Service onClick={() => this.handleClick(Constants.TREATMENTS)} image={tratamientos} name={Constants.TREATMENTS} />
                    <Service onClick={() => this.handleClick(Constants.PROMOTIONS)} image={promociones} name={Constants.PROMOTIONS} />
                    <Service onClick={() => this.handleClick(Constants.PARTIES)} image={b_party} name={Constants.PARTIES} />
                    <Service onClick={() => this.handleClick(Constants.WEDDINGS)} image={bodas} name={Constants.WEDDINGS} />
                </div>
                <AnimateHeight duration={500} height={service === Constants.NONE ? 0 : 'auto'}>
                    <Details service={service === Constants.NONE ? lastService : service} />
                </AnimateHeight>
            </div>
        );
    }

    renderMobile() {
        const servicesStyle = {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '50px'
        };
        const service = this.props.service;
        return (
            <div className='wrapper mobile'>
                <h1 className='title mobile'>Nuestros Servicios</h1>
                <div style={servicesStyle} name='servicios'>
                    <MobileService isActive={service === Constants.TREATMENTS} onClick={() => this.handleClick(Constants.TREATMENTS)} image={tratamientos} name={Constants.TREATMENTS} />
                    <MobileService isActive={service === Constants.PROMOTIONS} onClick={() => this.handleClick(Constants.PROMOTIONS)} image={promociones} name={Constants.PROMOTIONS} />
                    <MobileService isActive={service === Constants.PARTIES} onClick={() => this.handleClick(Constants.PARTIES)} image={b_party} name={Constants.PARTIES} />
                    <MobileService isActive={service === Constants.WEDDINGS} onClick={() => this.handleClick(Constants.WEDDINGS)} image={bodas} name={Constants.WEDDINGS} />
                </div>
            </div>
        );
    }

    render() {
        const sectionStyle = {
            position: 'relative',
            backgroundColor: '#F3FAFF',
            minHeight: '350px',
            paddingBottom: '120px'
        };
        return (
            <section style={sectionStyle}>
                {this.props.isMobile ?
                    this.renderMobile()
                    :
                    this.renderDesktop()
                }
                <Wave fillColor='white' />
            </section>
        );
    }
}


Services.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    service: PropTypes.string.isRequired,
    lastService: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired
};
