import React from 'react';


export default class Map extends React.Component {

    constructor() {
        super();
        this.getStyles = this.getStyles.bind(this);
    }

    getStyles() {
        return {
            mapWrapper: {
                position: 'relative'
            },
            map: {
                width: '100%',
                height: '500px'
            }
        };
    }


    render() {
        const styles = this.getStyles();
        return (
            <section style={styles.mapWrapper}>
                <div className="mapouter">
                    <div className="gmap_canvas">
                        <iframe title='map' style={styles.map} id="gmap_canvas" src="https://maps.google.com/maps?q=Oceans%20Est%C3%A9tica%20y%20Salud%2C%20Las%20Palmas%20de%20Gran%20Canaria&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" ></iframe>
                    </div>
                </div>
            </section>
        );
    }
}
