import React from 'react';


export default class CookiesBar extends React.Component {

    constructor() {
        super();
        this.state = {
            display: true
        };
    }


    render() {
        const handleClick = () => {
            this.setState({ display: false });
        };
        return (
            <section className='cookies-bar' style={{ visibility: (this.state.display ? 'visible' : 'hidden') }}>
                <p>Usamos cookies y procesamos datos de tu navegaci&oacute;n principalmente con fines t&eacute;nicos, estad&iacute;sticos y publicitarios, el uso de nuestra web implica tu consentimiento. 
                     <a href='cookies.html' target='_blank' rel='noopener noreferrer' style={{textDecoration: 'none'}}> M&aacute;s informaci&oacute;n.</a>
                </p>
                <div className='accept-cookies' onClick={handleClick}>
                    Aceptar
                </div>
            </section>
        );
    }
}

