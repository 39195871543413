import React from 'react';
import PropTypes from "prop-types";
import * as Constants from '../constants';
import background from '../images/servicios.jpg'
import Tabs from './Tabs'
import translation from '../i18n/es'


export default class Details extends React.Component {

    constructor() {
        super();
        this.getContent = this.getContent.bind(this);
        this.renderTreatments = this.renderTreatments.bind(this);
        this.renderPromotions = this.renderPromotions.bind(this);
        this.renderParties = this.renderParties.bind(this);
        this.renderWeddings = this.renderWeddings.bind(this);
        this.getSubclass = this.getSubclass.bind(this);
    }

    renderTreatments() {
        return (
            <Tabs
                labels={translation.treatments.map(({ title }) => title)}
                images={translation.treatments.map(({ image }) => image)}
                contents={translation.treatments.map(({ content }) => content)}
            />
        );
    }

    renderPromotions() {
        const handleError = (element) => {
            element.target.style = 'display: none';
        };
        return (
            <div className='promociones'>
                <img className='promocion' src={process.env.PUBLIC_URL + '/promocion1.jpg'} alt='Promoci&oacute;n1' />
                <img className='promocion' src={process.env.PUBLIC_URL + '/promocion2.jpg'} alt='Promoci&oacute;n2' onError={handleError} />
            </div>
        );
    }

    renderParties() {
        return (
            <div className='beauty-parties'>
                <div className='header'>
                    <p>Un d&iacute;a en Oceans exclusivamente para ti y tus amigas: desconexi&oacute;n, diversi&oacute;n y los mejores cuidados est&eacute;ticos.</p>
                    <p>¡Tú elijes el d&iacute;a y el pack* y nosotros nos encargamos del resto!</p>
                    <span>*Todos los packs incluyen aperitivo (bocados dulces y salados, zumo de naranja natural, caf&eacute;, infusiones, agua)</span>
                </div>
                <div className='packs'>

                    <div className='pack'>
                        <span>PACK 1</span>
                        <ul>
                            <li>Manicura Spa</li>
                            <li>Pedicura Spa</li>
                            <li>Depilación de cejas</li>
                        </ul>
                    </div>


                    <div className='pack'>
                        <span>PACK 2</span>
                        <ul>
                            <li>Manicura Spa</li>
                            <li>Depilación de cejas</li>
                            <li>Presoterapia 30 min</li>
                            <li>Tratamiento Iluminador Vitamina C</li>
                        </ul>
                    </div>


                    <div className='pack'>
                        <span>PACK 3</span>
                        <ul>
                            <li>Manicura Semipermanente</li>
                            <li>Presoterapia 30 min</li>
                            <li>Masaje Relajante localizado 30 min.</li>
                        </ul>
                    </div>


                    <div className='pack'>
                        <span>PACK 4</span>
                        <ul>
                            <li>Tratamiento facial Detox</li>
                            <li>Depilación de cejas</li>
                            <li>Presoterapia 30 min</li>
                        </ul>
                    </div>


                    <div className='pack'>
                        <span>PACK 5</span>
                        <ul>
                            <li>Manicura Tradicional</li>
                            <li>Pedicura Tradicional</li>
                            <li>Depilación de cejas</li>
                            <li>Tratamiento facial Detox</li>
                        </ul>
                    </div>

                </div>
            </div>
        );
    }

    renderWeddings() {
        return (
            <div className='weddings'>
                <Tabs
                    labels={translation.weddings.map(({ title }) => title)}
                    images={translation.weddings.map(({ image }) => image)}
                    contents={translation.weddings.map(({ content }) => content)}
                />
            </div>
        );
    }

    getContent() {
        if (this.props.service === Constants.TREATMENTS) return this.renderTreatments();
        if (this.props.service === Constants.PROMOTIONS) return this.renderPromotions();
        if (this.props.service === Constants.PARTIES) return this.renderParties();
        if (this.props.service === Constants.WEDDINGS) return this.renderWeddings();
    }

    getSubclass() {
        if (this.props.service === Constants.TREATMENTS) return 'details-box-1';
        if (this.props.service === Constants.PROMOTIONS) return 'details-box-2';
        if (this.props.service === Constants.PARTIES) return 'details-box-3';
        if (this.props.service === Constants.WEDDINGS) return 'details-box-4';
    }

    render() {
        const imageStyle = {
            width: '100%',
        };
        const subClass = this.getSubclass();
        return (
            <div style={{ position: 'relative', height: 'auto' }}>
                <img style={imageStyle} src={background} alt='services-background' />
                <div className={'border-up ' + subClass}>
                    <div className='border-up-left'></div>
                    <div className='border-up-right'></div>
                </div>
                <div className={'content-box arrow-container ' + subClass}>
                    <div className='arrow'></div>
                    <div className='top-border'></div>
                    {this.getContent()}
                </div>
            </div>
        );
    }
}


Details.propTypes = {
    service: PropTypes.string.isRequired
};
