
export const NONE = 'none';


export const HEADER = 'App';
export const TREATMENTS = 'Tratamientos';
export const PROMOTIONS = 'Promociones';
export const PARTIES = 'Beauty Parties';
export const WEDDINGS = 'Medicina Estética';


