import React from 'react';
import PropTypes from "prop-types";
import AnimateHeight from 'react-animate-height';

import hands_black from '../images/tabs/pies-y-manos.svg'
import hands_white from '../images/tabs/pies-y-manos-white.svg'

import depilacion_black from '../images/tabs/depilacion.svg'
import depilacion_white from '../images/tabs/depilacion-white.svg'

import facial_black from '../images/tabs/facial.svg'
import facial_white from '../images/tabs/facial-white.svg'

import corporal_black from '../images/tabs/corporal.svg'
import corporal_white from '../images/tabs/corporal-white.svg'

import novia_black from '../images/tabs/novia.svg'
import novia_white from '../images/tabs/novia-white.svg'


import estetica_black from '../images/tabs/estetica_black.svg'
import estetica_white from '../images/tabs/estetica_white.svg'

import novio_black from '../images/tabs/novio.svg'
import novio_white from '../images/tabs/novio-white.svg'

import invitados_black from '../images/tabs/invitados.svg'
import invitados_white from '../images/tabs/invitados-white.svg'


export default class DropdownButton extends React.Component {

    constructor() {
        super();
        this.getImage = this.getImage.bind(this);
        this.slugify = this.slugify.bind(this);
    }

    getImage(image) {
        const isActive = this.props.isActive;
        if (image === 'pies-y-manos.svg') return isActive ? hands_white : hands_black;
        if (image === 'depilacion.svg') return isActive ? depilacion_white : depilacion_black;
        if (image === 'facial.svg') return isActive ? facial_white : facial_black;
        if (image === 'corporal.svg') return isActive ? corporal_white : corporal_black;
        if (image === 'estetica.svg') return isActive ? estetica_white : estetica_black;
        if (image === 'novio.svg') return isActive ? novio_white : novio_black;
        if (image === 'invitados.svg') return isActive ? invitados_white : invitados_black;
    }

    slugify(text) {
        return text.toLowerCase().split(' ').join('-');
    }

    render() {
        const buttonName = this.slugify(this.props.label) + '-content';
        const handleClick = () => {
            this.props.callBack(this.props.label);/*
            setTimeout(() => {
                console.log('goto: ' + buttonName);
                var Scroll = require('react-scroll');
                var scroller = Scroll.scroller;
                scroller.scrollTo(buttonName, {
                    //duration: 500,
                    offset: -83,
                    smooth: true,
                    isDynamic: true // See no difference with this..
                });
            }, 310);*/
        };
        const createDOMPurify = require('dompurify');
        const { JSDOM } = require('jsdom');
        const window = (new JSDOM('')).window;
        const DOMPurify = createDOMPurify(window);
        const content = DOMPurify.sanitize(this.props.content).split('</div>,<div').join('</div><div');

        return (
            <div id={buttonName} name={buttonName} className='mobile-drop-down-button'>
                <div className={'head' + (this.props.isActive ? ' active' : '')} onClick={handleClick} >
                    <img className='mobile-image' src={this.getImage(this.props.image)} alt='imagen' />
                    <p>{this.props.label.toUpperCase()}</p>
                </div>
                <AnimateHeight className='content-animation' duration={300} height={this.props.isActive ? 'auto' : 0}>
                    <div className='content' dangerouslySetInnerHTML={{ __html: content }} />
                </AnimateHeight>
            </div>
        );
    }
}


DropdownButton.propTypes = {
    image: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    content: PropTypes.array.isRequired,
    isActive: PropTypes.bool.isRequired,
    callBack: PropTypes.func.isRequired
};
