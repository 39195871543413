import React from 'react';
import './App.css';
import './Arrow.css';
import './Mobile.css';
import './animate.min.css';
import Services from './components/Services';
import Contact from './components/Contact'
import Wave from './components/Wave';
import CookiesBar from './components/CookiesBar';
import NavBar from './components/NavBar';
import { Parallax } from 'react-parallax';
import * as Constants from './constants';

import sorisa from './images/sorisa.png';
import kinelics from './images/kinelics.png';
import cocoon from './images/cocoon.png';
import skinclinic from './images/skinclinic.png';
import lpg from './images/lpg.png';
import FooterLogo from './images/logo-footer.png';

function isMobile() {
  return (window.innerWidth <= 800);
}


export default class App extends React.Component {

  constructor() {
    super();
    this.state = {
      isMobile: isMobile(),
      service: Constants.NONE,
      lastService: Constants.NONE
    }
    this.updateDimensions = this.updateDimensions.bind(this);
    this.getStyles = this.getStyles.bind(this);
    this.updateService = this.updateService.bind(this);
  }

  updateDimensions() {
    this.setState({ isMobile: isMobile() });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getStyles() {
    return {
      footer: {
        width: '98%',
        heiht: '900px',
        padding: '1%',
        color: 'white',
        backgroundColor: '#0062ab',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        flexWrap: 'wrap'
      },
      header: {
        minWidth: '320px',
        width: '100%',
        position: 'relative'
      },
      parallax: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    };
  }

  updateService(service) {
    const lastService = this.state.service;
    this.setState({ service, lastService });
  }

  render() {
    const styles = this.getStyles();
    const isMobile = this.state.isMobile;
    const class_ = (isMobile ? ' mobile' : '');
    return (
      <div className='App' >
        <NavBar callback={this.updateService} isMobile={isMobile} />
        <header className='oceans-header' style={styles.header}>
          <Parallax className='slow animated fadeIn' style={styles.parallax} bgImage={require('./images/header1.jpg')} strength={500}>
            <div className={'banner' + class_}>
              <h1 className={'banner-title' + class_}>OCEANS</h1>
              <p className={'text' + class_}>
                El lugar donde la belleza de cuerpo y alma, encuentran el equilibrio.
              </p>
            </div>
          </Parallax>
          <Wave fillColor='#F3FAFF' isMobile={isMobile} />
        </header>
        <main className='oceans-main'>
          <Services service={this.state.service} lastService={this.state.lastService} callback={this.updateService} isMobile={isMobile} />
          <Contact isMobile={isMobile} />
          <section className={'brands' + class_}>
            <div className={'wrapper' + class_}>
              <h1 className={'title' + class_}>Nuestras marcas</h1>
              <div className='brand-img-container'>
                <img src={sorisa} alt='sorisa' />
                <img src={kinelics} alt='kinelics' />
                <img src={cocoon} alt='cocoon' />
                <img src={skinclinic} alt='skinclinic' />
                <img src={lpg} alt='lpg' />
              </div>
            </div>
          </section>
        </main>
        <footer className={class_} style={styles.footer}>
          <p><a href='aviso_legal.html' target='_blank' rel='noopener noreferrer'>Aviso legal</a> y <a href='privacidad.html' target='_blank' rel='noopener noreferrer'>pol&iacute;tica de privacidad</a></p>
          <img src={FooterLogo} alt='FooterLogo'></img>
          <p>Todos los derechos reservados | &copy;	2019</p>
        </footer>
        <div className='texto-legal'>
          <p>OCEANS ESTETICA Y SALUD</p>
          <p>Avda. Alcalde Ramírez Bethencourt, 21 Pasaje Trasero, Local 46 Bajo - 35004 Las Palmas de Gran Canaria (Las Palmas) – Telf. 686.098.211</p>
        </div>
        <CookiesBar />
      </div>
    );
  }
}


