import React from 'react';
import PropTypes from "prop-types";


export default class Service extends React.Component {

    constructor() {
        super();
        this.getStyles = this.getStyles.bind(this);
    }

    getStyles() {
        return {
            section: {
                margin: '18px',
                userSelect: 'none'
            },
            image: {
                width: '62px'
            },
            name: {
                color: 'rgb(162, 99, 18)',
                fontWeight: 'bold',
                margin: '0',
                letterSpacing: '0.2rem'
            }
        };
    }


    render() {
        const styles = this.getStyles();
        const { image, name } = { ...this.props };
        return (
            <div className='hover' style={styles.section} name={name} onClick={this.props.onClick}>
                <img style={styles.image} src={image} alt={name} />
                <p style={styles.name}>{name}</p>
            </div>
        );
    }
}


Service.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};
