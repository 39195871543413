import React from 'react';
import Wave from './Wave';
import Map from './Map'
import Media from './Media'
import BigLogo from '../images/BigLogo.png'
import facebook from '../images/facebook.svg'
import whatsapp from '../images/whatsapp.svg'
import instagram from '../images/instagram.svg'
import telefono from '../images/telefono.svg'
import PropTypes from "prop-types";


export default class Contact extends React.Component {

    constructor() {
        super();
        this.getStyles = this.getStyles.bind(this);
    }

    getStyles() {
        return {
            section: {
                position: 'relative'
            },
            content: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                flexDirection: 'row',
                flexWrap: 'wrap',
                margin: '24px',
            },
            extract: {
                width: '300px',
                textAlign: 'justify'
            },
            logo: {
                minWidth: '300px',
                maxWidth: '400px',
                margin: '24px 0'
            }
        };
    }


    render() {
        const styles = this.getStyles();
        const class_ = (this.props.isMobile ? ' mobile' : '');
        const phone_number = '928 206 699';
        return (
            <section style={styles.section} name='contacto'>
                <div className={'wrapper' + class_}>
                    <h1 className={'title' + class_}>Contacto</h1>
                    <div style={styles.content}>
                        <Media icon={telefono} link={(this.props.isMobile ? 'tel:+34' + phone_number : 'null')} text={phone_number} />
                        <Media icon={whatsapp} link='https://wa.me/34686098211' text='686 098 211' />
                        <Media icon={facebook} link='https://www.facebook.com/oceanslaspalmas' text='Oceans. Estética y Salud' />
                        <Media icon={instagram} link='https://www.instagram.com/oceanslaspalmas' text='@oceanslaspalmas' />
                    </div>
                    <div style={styles.content}>
                        <div style={styles.extract}>
                            <p>Somos un equipo de profesionales tituladas en est&eacute;tica integral y bienestar. Sumamos 25 a&ntilde;os de experiencia en el sector de la est&eacute;tica integral, avanzada y medicina est&eacute;tica.</p>
                            <p>En nuestro centro, combinamos las t&eacute;cnicas m&aacute;s tradicionales con las m&aacute;s avanzadas.  apostamos por tratamientos sencillos, altamente eficaces y m&iacute;nimamente invasivos, procurando a nuestros clientes una experiencia &uacute;nica y personalizada, en un ambiente c&oacute;modo, arm&oacute;nico y relajante</p>
                        </div>
                        <img className={'BigLogo' + class_} style={styles.logo} src={BigLogo} alt='BigLogo' />
                    </div>
                </div>
                <Map />
                <Wave fillColor='#F3FAFF' />
            </section>
        );
    }
}


Contact.propTypes = {
    isMobile: PropTypes.bool.isRequired
};