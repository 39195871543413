import React from 'react';
import PropTypes from "prop-types";


export default class Media extends React.Component {

    render() {
        const styles = {
            icon: {
                width: '36px'
            },
            text: {
                margin: '0',
                color: 'black',
                fontWeight: 'bold'
            }
        };
        const { icon, link, text } = { ...this.props };
        if (link === 'null') {
            return (
                <div className='web-link phone' >
                    <img className='icon' style={styles.icon} src={icon} alt='icono' />
                    <p style={styles.text}>{text}</p>
                </div>
            );
        } else {
            return (
                <a className='web-link' href={link} target='_blank' rel='noopener noreferrer'>
                    <img className='icon' style={styles.icon} src={icon} alt='icono' />
                    <p style={styles.text}>{text}</p>
                </a>
            );
        }
    }
}


Media.propTypes = {
    icon: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
};